import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  IconButton,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  Box,
} from "@mui/material";
import Iconify from "../../../../components/Iconify";
import { getTirages } from "../../../../redux/slices/generale";
import { useSelector, useDispatch } from "react-redux";

const Tirages = ({ open, handleClose }) => {
  const dispatch = useDispatch();
  const tirages = useSelector((state) => state.generale.tirages);

  const fetchData = async () => {
    try {
      dispatch(getTirages());
    } catch (error) {
      console.error("Erreur:", error);
    }
  };

  useEffect(() => {
    if (open) {
      fetchData();
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Typography variant="h6">Tirages</Typography>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{ position: "absolute", right: 8, top: 8 }}
        >
          <Iconify icon={"eva:close-fill"} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <List>
          {tirages?.map((tirage, index) => (
            <React.Fragment key={index}>
              <ListItem>
                <ListItemText
                  primary={
                    <Typography
                      sx={{
                        color: tirage.status ? "green" : "red",
                        fontWeight: "bold",
                      }}
                    >
                      {tirage.name}
                    </Typography>
                  }
                  secondary={`Ouverture : ${tirage.start_time} / Fermeture : ${tirage.end_time}`}
                />
              </ListItem>
              {index < tirages?.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  );
};

export default Tirages;
