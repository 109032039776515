import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Box,
  Button,
} from "@mui/material";
import Iconify from "../../../../components/Iconify";
import RepportPartiel from "./repports/RepportPartiel";
import RepportFinTirage from "./repports/RepportFinTirage";
import RepportFicheGagnant from "./repports/RepportFicheGagnant";

const Repports = ({ open, handleClose }) => {
  const [activeMenu, setActiveMenu] = useState("PARTIEL");

  const renderContent = () => {
    switch (activeMenu) {
      case "PARTIEL":
        return <RepportPartiel open={open} />;
      case "FIN_TIRAGE":
        return <RepportFinTirage />;
      case 'F_GAGNANT':
        return <RepportFicheGagnant />;
      default:
        return null;
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Typography variant="h6">Rapports</Typography>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{ position: "absolute", right: 8, top: 8 }}
        >
          <Iconify icon={"eva:close-fill"} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box display="flex" justifyContent="space-around" mb={2}>
          <Button
            variant={activeMenu === "PARTIEL" ? "contained" : "outlined"}
            onClick={() => setActiveMenu("PARTIEL")}
          >
            PARTIEL
          </Button>
          <Button
            variant={activeMenu === "FIN_TIRAGE" ? "contained" : "outlined"}
            onClick={() => setActiveMenu("FIN_TIRAGE")}
          >
            FIN TIRAGE
          </Button>
          <Button
            variant={activeMenu === "F_GAGNANT" ? "contained" : "outlined"}
            onClick={() => setActiveMenu("F_GAGNANT")}
          >
            F.GAGNANT
          </Button>
        </Box>
        {renderContent()}
      </DialogContent>
    </Dialog>
  );
};

export default Repports;
