import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider as MUIThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import useSettings from '../hooks/useSettings';
import palette from './palette';
import typography from './typography';
import breakpoints from './breakpoints';
import componentsOverride from './overrides';
import shadows, { customShadows } from './shadows';

ThemeProvider.propTypes = {
  children: PropTypes.node,
};

export default function ThemeProvider({ children }) {
  const { themeMode, themeDirection } = useSettings();

  // Créez un objet de palettes pour mapper themeMode aux palettes correspondantes
  const palettes = {
    light: palette.light,
    dark: palette.dark,
    default: palette.default,
    blue: palette.blue,
    orange: palette.orange,
    cyan: palette.cyan,
    red: palette.red,
    purple: palette.purple,
  };

  const selectedPalette = palettes[themeMode];

  const isLight = themeMode === 'light';
  const isDefault = themeMode === 'default';

  const themeOptions = useMemo(
    () => ({
      palette: selectedPalette, // Utilisez la palette correspondante en fonction de themeMode
      typography,
      breakpoints,
      shape: { borderRadius: 8 },
      direction: themeDirection,
      shadows: isLight ? shadows.light : shadows.dark,
      customShadows: isLight ? customShadows.light : customShadows.dark,
    }),
    [selectedPalette, isLight, themeDirection]
  );

  const theme = createTheme(themeOptions);
  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
}
