/* eslint-disable */
// ----------------------------------------------------------------------

export default function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

const DEVICE_ID_KEY = "lpcenter-device-id";

const generateNumericId = (length) => {
  let result = "";
  const characters = "0123456789";
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
};

export const getDeviceId = () => {
  let deviceId = localStorage.getItem(DEVICE_ID_KEY) || "";
  if (!deviceId) {
    deviceId = generateNumericId(16);
    localStorage.setItem(DEVICE_ID_KEY, deviceId);
  }
  return deviceId;
};

export const generateUniqueId = (bets) => {
  return bets?.length + 1 + Date.now();
};