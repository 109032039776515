import { useState } from "react";
// @mui
import { Box, Divider, Typography, Button } from "@mui/material";
// components
import MenuPopover from "../../../components/MenuPopover";

// ----------------------------------------------------------------------

const TUTO_OPTIONS = [
  {
    title: "Borlette",
    subtitle: "Simple : xx",
    subtitle2: "Koche a goch pou virel",
  },
  {
    title: "Pick 2",
    subtitle: "Simple : pxx",
    subtitle2: "Koche a goch pou virel",
  },
  {
    title: "Boul Pè",
    subtitle: "Simple : bp",
    subtitle2: "",
  },
  {
    title: "Loto 3 auto",
    subtitle: "Simple : l3",
    subtitle2: "",
  },
  {
    title: "Loto 4 auto",
    subtitle: "Simple : l4",
    subtitle2: "",
  },
  {
    title: "Loto 4 auto complet",
    subtitle: "Simple : xxxx+",
    subtitle2: "",
  },
  {
    title: "Mariage auto",
    subtitle: "Simple : ma",
    subtitle2: "",
  },
  {
    title: "Mariage auto complet",
    subtitle: "Simple : xxxxm",
    subtitle2: "",
  },
  {
    title: "Reverce auto",
    subtitle: "Simple : rv",
    subtitle2: "",
  },
  {
    title: "Point auto",
    subtitle: "Simple : px",
    subtitle2: "",
  },
  {
    title: "Loto3",
    subtitle: "Simple : xxx",
    subtitle2: "",
  },
  {
    title: "Loto3 Box",
    subtitle: "Simple : bxxx",
    subtitle2: "Koche a goch pou virel",
  },
  {
    title: "Mariage",
    subtitle: "Simple : xxxx",
    subtitle2: "Koche a goch pou virel",
  },
  {
    title: "Loto4",
    subtitle: "Option x : xxxx.option",
    subtitle2: "Koche a goch pou virel",
  },
  {
    title: "Loto4 Box",
    subtitle: "Simple : bxxxx",
    subtitle2: "Koche a goch pou virel",
  },
  {
    title: "Loto4",
    subtitle: "Tout option : xxxx.0",
    subtitle2: "Koche a goch pou virel",
  },
  {
    title: "Loto5",
    subtitle: "Option x : xxxxx.option",
    subtitle2: "",
  },
  {
    title: "Loto5",
    subtitle: "Tout option : xxxxx.0",
    subtitle2: "Koche a goch pou virel",
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      <Button
        onClick={handleOpen}
        variant="contained"
        sx={{ textTransform: "uppercase" }}
      >
        Tuto
      </Button>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          "& .MuiMenuItem-root": {
            typography: "body2",
            borderRadius: 0.75,
          },
        }}
      >
        <Box
          sx={{
            height: 500,
            overflowY: "auto",
          }}
        >
          <Box sx={{ my: 1.5, px: 2.5 }}>
            <Typography variant="body2">
              Koche a dwat pou anrejistré montan an 
            </Typography>
            <Typography variant="body2">
              NB :  x reprezanté numéro yo
            </Typography>
          </Box>
          {TUTO_OPTIONS.map((option, index) => (
            <Box key={index}>
              <Box sx={{ my: 1.5, px: 2.5 }}>
                <Typography variant="subtitle2">{option?.title}</Typography>
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  {option?.subtitle}
                </Typography>
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  {option?.subtitle2}
                </Typography>
              </Box>

              <Divider sx={{ borderStyle: "dashed" }} />
            </Box>
          ))}
        </Box>
      </MenuPopover>
    </>
  );
}
