import React, { useState, useEffect } from "react";
import moment from "moment";
import "moment/locale/fr";

const DateTimeDisplay = ({style}) => {
  const [currentTime, setCurrentTime] = useState(moment());

  useEffect(() => {
    moment.locale('fr');
    const timer = setInterval(() => {
      setCurrentTime(moment());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return <div style={style}>{currentTime.format("h:mm:ss A | dddd DD MMM YYYY")}</div>;
};

export default DateTimeDisplay;
