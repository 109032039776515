import { createSlice } from "@reduxjs/toolkit";
import { generateUniqueId } from "../../utils/uuidv4";
import axios from "../../utils/axios";
import { convertToDict } from "../../utils/games";

// ----------------------------------------------------------------------

const initialState = {
  bets: [],
  isMultiTirage: true,
};

const slice = createSlice({
  name: "ticket",
  initialState,
  reducers: {
    addBet: (state, action) => {
      const { tirage, lotto, boule, option, montant } = action.payload;
      state.bets.push({
        id: generateUniqueId(state.bets),
        tirage,
        lotto,
        boule,
        option,
        montant,
      });
    },
    deleteBouleById: (state, action) => {
      const id = action.payload;
      state.bets = state.bets.filter((bet) => bet.id !== id);
    },
    deleteBoulesByLotto: (state, action) => {
      const lotto = action.payload;
      state.bets = state.bets.filter((bet) => bet.lotto !== lotto);
    },
    clearBets: (state) => {
      state.bets = [];
    },
    setIsMultiTirage: (state, action) => {
      state.isMultiTirage = action.payload;
    },
    updateBetAmountById: (state, action) => {
      const { id, amountToAdd } = action.payload;
      const bet = state.bets.find((bet) => bet.id === id);
      if (bet) {
        bet.montant += amountToAdd;
      }
    },
  },
});

// Reducer
export default slice.reducer;
export const {
  addBet,
  deleteBouleById,
  deleteBoulesByLotto,
  clearBets,
  setIsMultiTirage,
  updateBetAmountById,
} = slice.actions;
// ----------------------------------------------------------------------

export const selectFilteredBets = (state) => {
  const filteredBets = state.ticket.bets;

  return {
    BO: filteredBets.filter((bet) => bet.lotto === "BO" || bet.lotto === "P2"),
    MA: filteredBets.filter((bet) => bet.lotto === "MA"),
    L3: filteredBets.filter((bet) => bet.lotto === "L3" || bet.lotto === "L3B"),
    L4_L5: filteredBets.filter(
      (bet) => bet.lotto === "L4" || bet.lotto === "L5" || bet.lotto === "L4B"
    ),
  };
};

export const selectTotalAmount = (state) => {
  return state.ticket.bets.reduce((total, bet) => total + bet.montant, 0);
};

export async function createTicket(bets) {
  const ticket = convertToDict(bets);
  const response = await axios.post(`/api/desk/fiche`, ticket);
  return response.data;
}

export async function getTickets(start_date, end_date, refCode) {
  const params = new URLSearchParams();
  params.append("start_date", start_date);
  params.append("end_date", end_date);
  if (refCode) {
    params.append("refCode", refCode);
  }
  const response = await axios.get(`/api/desk/fiche?${params.toString()}`);
  return response.data;
}

export async function getTicketsWin(start_date, end_date, refCode) {
  const params = new URLSearchParams();
  params.append("start_date", start_date);
  params.append("end_date", end_date);
  if (refCode) {
    params.append("refCode", refCode);
  }
  const response = await axios.get(`/api/desk/fiche-win?${params.toString()}`);
  return response.data;
}

export async function getTicket(id) {
  const response = await axios.get(`/api/games/find-fiche?barcode=${id}`);
  return response.data;
}

export async function deleteTicket(id) {
  const response = await axios.delete(`/api/desk/fiche-delete/${id}/`);
  return response.data;
}

export async function payTicket(id) {
  const response = await axios.put(`/api/desk/fiche-pay/${id}/`);
  return response.data;
}
