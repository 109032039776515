import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
//
import { dispatch } from "../store";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  isLoadingTirage: false,
  error: null,
  tirages: [],
};

const slice = createSlice({
  name: "generale",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // START LOADING TIRAGE
    startLoadingTirage(state) {
      state.isLoadingTirage = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET TIRAGES
    getTiragesSuccess(state, action) {
      state.isLoadingTirage = false;
      state.tirages = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getTirages() {
  return async () => {
    dispatch(slice.actions.startLoadingTirage());
    try {
      const response = await axios.get("/api/desk/tirage");
      dispatch(slice.actions.getTiragesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function getLotGagnants(date) {
  const params = new URLSearchParams();
  params.append("date", date);
  const response = await axios.get(
    `/api/games/list-tirage?${params.toString()}`
  );
  return response.data;
}

export async function getRepports(
  start_date = new Date(),
  end_date = new Date(),
  tirage,
  fgagnant
) {
  const params = new URLSearchParams();
  params.append("start_date", start_date);
  params.append("end_date", end_date);
  params.append("tirage_id", tirage);
  params.append("fgagnant", fgagnant);
  const response = await axios.get(`/api/games/rapports?${params.toString()}`);
  return response.data;
}
