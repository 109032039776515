import React, { useState, useCallback, useContext, createContext, useReducer } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  CircularProgress,
  Box,
} from '@mui/material';

// ----------------------------------------------------------------------

const initialState = {
  dialogVisible: false,
  inputValue: '',
  dialogConfig: {},
  resolve: null,
};

const handlers = {
  OPEN_DIALOG: (state, action) => {
    const { config, resolve } = action.payload;
    return {
      ...state,
      dialogConfig: config,
      dialogVisible: true,
      resolve,
    };
  },
  CLOSE_DIALOG: (state) => ({
    ...state,
    dialogVisible: false,
  }),
  SET_INPUT_VALUE: (state, action) => ({
    ...state,
    inputValue: action.payload,
  }),
  SUBMIT_VALUE: (state) => {
    state.resolve(state.inputValue); // Resolve the promise with the input value
    return {
      ...state,
      dialogVisible: false,
    };
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AskValueContext = createContext({
  ...initialState,
  openDialog: () => {},
});

function AskValueProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [loading, setLoading] = useState(false);

  const openDialog = (config) => {
    return new Promise((resolve) => {
      dispatch({
        type: 'OPEN_DIALOG',
        payload: {
          config,
          resolve,
        },
      });
    });
  };

  const closeDialog = () => {
    dispatch({
      type: 'CLOSE_DIALOG',
    });
  };

  const setInputValue = (value) => {
    dispatch({
      type: 'SET_INPUT_VALUE',
      payload: value,
    });
  };

  const submitValue = async () => {
    try {
      setLoading(true);
      await dispatch({
        type: 'SUBMIT_VALUE',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      submitValue();
    }
  };

  return (
    <AskValueContext.Provider value={{ ...state, openDialog }}>
      {children}
      <Dialog open={state.dialogVisible} onClose={closeDialog}>
        <DialogTitle>{state.dialogConfig?.title || 'Saisissez une valeur :'}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Valeur"
            type="number"
            fullWidth
            variant="outlined"
            value={state.inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={handleKeyDown} // Détecte l'appui sur "Enter"
          />
        </DialogContent>
        <DialogActions>
          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
              <CircularProgress size={24} />
            </Box>
          ) : (
            <>
              <Button onClick={closeDialog} color="primary">
                Annuler
              </Button>
              <Button onClick={submitValue} color="primary">
                Valider
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </AskValueContext.Provider>
  );
}

const useAskValue = () => {
  const context = useContext(AskValueContext);
  if (!context) {
    throw new Error('useAskValue must be used within AskValueProvider');
  }
  return context;
};

export { AskValueProvider, useAskValue };
