import PropTypes from "prop-types";
// @mui
import { styled } from "@mui/material/styles";
import { List, Box, ListSubheader } from "@mui/material";
//
import { NavListRoot } from "./NavList";
import handlePermission from "../../../utils/handlePerm";

// ----------------------------------------------------------------------

export const ListSubheaderStyle = styled((props) => (
  <ListSubheader disableSticky disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.overline,
  paddingTop: theme.spacing(3),
  paddingLeft: theme.spacing(2),
  paddingBottom: theme.spacing(1),
  color: theme.palette.text.secondary,
  transition: theme.transitions.create("opacity", {
    duration: theme.transitions.duration.shorter,
  }),
}));

// ----------------------------------------------------------------------

NavSectionVertical.propTypes = {
  isCollapse: PropTypes.bool,
  permissions_list: PropTypes.array,
  navConfig: PropTypes.array,
};

export default function NavSectionVertical({
  permissions_list,
  navConfig,
  isCollapse = false,
  ...other
}) {
  return (
    <Box {...other}>
      {navConfig.map((group) =>
        group?.perm ? (
          handlePermission(permissions_list, group?.perm) && (
            <List key={group.subheader} disablePadding sx={{ px: 2 }}>
              <ListSubheaderStyle
                sx={{
                  ...(isCollapse && {
                    opacity: 0,
                  }),
                }}
              >
                {group.subheader}
              </ListSubheaderStyle>

              {group.items.map((list) =>
                list?.perm ? (
                  handlePermission(permissions_list, list?.perm) && (
                    <NavListRoot
                      key={list.title}
                      list={list}
                      isCollapse={isCollapse}
                    />
                  )
                ) : (
                  <NavListRoot
                    key={list.title}
                    list={list}
                    isCollapse={isCollapse}
                  />
                )
              )}
            </List>
          )
        ) : (
          <List key={group.subheader} disablePadding sx={{ px: 2 }}>
            <ListSubheaderStyle
              sx={{
                ...(isCollapse && {
                  opacity: 0,
                }),
              }}
            >
              {group.subheader}
            </ListSubheaderStyle>

            {group.items.map((list) =>
              list?.perm ? (
                handlePermission(permissions_list, list?.perm) && (
                  <NavListRoot
                    key={list.title}
                    list={list}
                    isCollapse={isCollapse}
                  />
                )
              ) : (
                <NavListRoot
                  key={list.title}
                  list={list}
                  isCollapse={isCollapse}
                />
              )
            )}
          </List>
        )
      )}
    </Box>
  );
}
